export const REPORT_CREATE_REQUEST = "REPORT_CREATE_REQUEST";
export const REPORT_CREATE_SUCCESS = "REPORT_CREATE_SUCCESS";
export const REPORT_CREATE_FAIL = "REPORT_CREATE_FAIL";


export const MESSAGE_LIST_MY_REQUEST = "MESSAGE_LIST_MY_REQUEST";
export const MESSAGE_LIST_MY_SUCCESS = "MESSAGE_LIST_MY_SUCCESS";
export const MESSAGE_LIST_MY_FAIL = "MESSAGE_LIST_MY_FAIL";
export const MESSAGE_LIST_MY_RESET = "MESSAGE_LIST_MY_RESET";

export const MESSAGE_DETAILS_LIST_MY_REQUEST = "MESSAGE_DETAILS_LIST_MY_REQUEST";
export const MESSAGE_DETAILS_LIST_MY_SUCCESS = "MESSAGE_DETAILS_LIST_MY_SUCCESS";
export const MESSAGE_DETAILS_LIST_MY_FAIL = "MESSAGE_DETAILS_LIST_MY_FAIL";
export const MESSAGE_DETAILS_LIST_MY_RESET = "MESSAGE_DETAILSLIST_MY_RESET";
